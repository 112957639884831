import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  GET_ORDER_TAG_PRIORITY,
  UPDATE_ORDER_TAG_PRIORITY,
  CREATE_ORDER_TAG_PRIORITY,
  GET_ORDER_TAG_PRIORITY_LOADING,
  UPDATE_ORDER_TAG_PRIORITY_POSITION,
  GET_ORDER_TAG_PRIORITY_ERROR,
  DELETE_ORDER_TAG_PRIORITY,
  ADD_TAG
} from '../constants';

export function GetTagPriorityList() {
  return async dispatch => {
    dispatch({type: GET_ORDER_TAG_PRIORITY_LOADING});
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.get(`${url}/priority_cards.json`, {
        headers: {Authorization: `Bearer ${access_token}`}
      });
      dispatch({
        type: GET_ORDER_TAG_PRIORITY,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_TAG_PRIORITY_ERROR,
        payload: error?.response?.status
      });
    }
  };
}

export function UpdateTagPriority(
  id,
  updatedData,
  callBack,
  isToUpdate = true
) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.put(
        `${url}/priority_cards/${id}.json`,
        updatedData,
        {
          headers: {Authorization: `Bearer ${access_token}`}
        }
      );
      if (isToUpdate) {
        dispatch({
          type: UPDATE_ORDER_TAG_PRIORITY,
          payload: response.data
        });
      }
      if (callBack) callBack();
    } catch (error) {
      const message =
        error.response?.data?.messages || 'An unexpected error occurred';
      if (callBack) callBack(message);
    }
  };
}

export function CreateTagPriority(data, callBack) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.post(`${url}/priority_cards.json`, data, {
        headers: {Authorization: `Bearer ${access_token}`}
      });
      dispatch({
        type: CREATE_ORDER_TAG_PRIORITY,
        payload: response?.data
      });
      if (callBack) callBack();
    } catch (error) {
      const message =
        error.response?.data?.assigned_tag[0] || 'An unexpected error occurred';
      if (callBack) callBack(`tag ${message}`);
    }
  };
}
export function CreateUserTagPriority(data, callBack) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.post(
        `${url}/priority_cards/create_with_user.json`,
        data,
        {
          headers: {Authorization: `Bearer ${access_token}`}
        }
      );
      dispatch({
        type: CREATE_ORDER_TAG_PRIORITY,
        payload: response.data.priority_card
      });
      if (callBack) callBack();
    } catch (error) {
      const message =
        error.response?.data?.assigned_tag[0] || 'An unexpected error occurred';
      if (callBack) callBack(`user ${message}`);
    }
  };
}

export function UpdateTagPriorityPositions(data, callBack) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.post(
        `${url}/priority_cards/update_positions.json`,
        data,
        {
          headers: {Authorization: `Bearer ${access_token}`}
        }
      );
      dispatch({
        type: UPDATE_ORDER_TAG_PRIORITY_POSITION,
        payload: response.data
      });
      if (callBack) callBack();
    } catch (error) {
      if (callBack) callBack(error);
    }
  };
}

export function handleCombineUpdate(attributeUpdate, positionUpdate) {
  return dispatch => {
    const {id, updatedData} = attributeUpdate;
    const callBack = () => {
      dispatch(UpdateTagPriorityPositions(positionUpdate));
    };
    dispatch(UpdateTagPriority(id, updatedData, callBack, false));
  };
}

export function DeleteTagPriority(id, callBack) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      await axios.delete(`${url}/priority_cards/${id}.json`, {
        headers: {Authorization: `Bearer ${access_token}`}
      });
      dispatch({
        type: DELETE_ORDER_TAG_PRIORITY,
        payload: id
      });
      if (callBack) callBack();
    } catch (error) {
      if (callBack) callBack(error);
    }
  };
}

export function ToggleAddTag(displayAddTag) {
  return async dispatch => {
    dispatch({
      type: ADD_TAG,
      payload: displayAddTag
    });
  };
}
