import React, {useEffect, useState, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {GetAllTags} from '../../../actions/orderActions';
import {OrderTagsView} from './orderTagsView';
import {ActivityIndicator} from 'react-native';
import {GetAllUsers} from '../../../actions/userAction';
import {UserTagsView} from './userTagsView';

export const OrderTagsContainer = ({
  setTag,
  isUserTag,
  editingTagName = ''
}) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const orderTags = useSelector(state => state.order.ordersTags?.data || []);
  const users = useSelector(state => state.user.users || []);
  const [filteredTags, setFilteredTags] = useState(orderTags);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [selectedTag, setSelectedTag] = useState();
  const [isEditing, setIsEditing] = useState();
  const [newItem, setNewItem] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    dispatch(GetAllTags(handleCallback));
    dispatch(GetAllUsers());
  }, [dispatch]);

  const handleCallback = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredTags(orderTags);
    } else {
      const filtered = orderTags.filter(tag =>
        tag.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredTags(filtered);
    }
  }, [searchQuery, orderTags]);

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(user =>
        user.username.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [searchQuery, users]);

  const groovePackerTags = useMemo(() => {
    return filteredTags.filter(tag => tag.groovepacker_tag_origin);
  }, [filteredTags]);

  const otherTags = useMemo(() => {
    return filteredTags.filter(tag => !tag.groovepacker_tag_origin);
  }, [filteredTags]);

  useEffect(() => {
    if (editingTagName) {
      let tag = filteredTags.find(tag => tag.name === editingTagName);
      if (isUserTag) {
        tag = users.find(user => user.username === editingTagName);
      }
      if (tag) {
        setSelectedTag(tag);
        setTag(tag);
      }
    }
  }, [filteredTags, editingTagName, isUserTag, users]);

  const handleTagUpdate = tag => {
    setSelectedTag(tag);
    setTag(tag);
    setIsFirstLoad(false)
  };

  const addNewTag = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const randomInt = array[0] % 1000;
    const newTag = {
      id: randomInt,
      name: '',
      color: '#808080',
      isVisible: true
    };
    setFilteredTags(prevTags => [newTag, ...prevTags]);
    setNewItem(newTag);
  };

  return (
    <>
      {isLoading ? (
        <ActivityIndicator color={'white'} />
      ) : (
        <>
          {isUserTag ? (
            <UserTagsView
              users={filteredUsers}
              searchQuery={searchQuery}
              selectedTag={selectedTag}
              setSearchQuery={text => setSearchQuery(text)}
              handleTagUpdate={handleTagUpdate}
              isFirstLoad={isFirstLoad}
            />
          ) : (
            <OrderTagsView
              searchQuery={searchQuery}
              setSearchQuery={text => setSearchQuery(text)}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              groovePackerTags={groovePackerTags}
              otherTags={otherTags}
              selectedTag={selectedTag}
              handleTagUpdate={handleTagUpdate}
              addNewTag={addNewTag}
              newItem={newItem}
              isFirstLoad={isFirstLoad}
            />
          )}
        </>
      )}
    </>
  );
};

export default OrderTagsContainer;
