import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import styles from './styles';

export const UserList = ({users, selectedUser, setSelectedUser}) => {
  return (
    <View>
      {users?.map((user, index) => (
        <View key={index} style={styles.tagContainer}>
          <TouchableOpacity
           testID='SelectUser'
            onPress={() => setSelectedUser(user)}
            style={[
              styles.tagWrapper,
              selectedUser === user && styles.selectedTagWrapper
            ]}>
            <Text style={styles.tagText} numberOfLines={1}>
              {user.username}
            </Text>
          </TouchableOpacity>
          {selectedUser === user && (
            <TouchableOpacity testID='RemoveSelectedUser' onPress={() => setSelectedUser(null)}>
              <i className="icon-cancel" style={styles.cancelIcon}></i>
            </TouchableOpacity>
          )}
        </View>
      ))}
    </View>
  );
};
