import { StyleSheet } from 'react-native';
import {fontFamily} from '../helpers/fontFamily';

const styles = StyleSheet.create({
  alertBox : {
    backgroundColor: '#b7b7b7',
    padding: 18,
    borderColor: '#e8971a',
    borderWidth: 3,
    boxShadow: '2px 2px 10px #363636'
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 20
  },
  boxContainerOne: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5
  },
  boxContainerTwo: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxContainerThree: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  boxContainerThree: {
    position: 'absolute',
    right: '40%',
    top: -10,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomColor: '#8d9fb1'
  },
  gradientContainer:{
    width: "100%",
    justifyContent: 'space-between',
    top: '20%',
    shadowColor: '#000',
    borderRadius: 10,
    padding: 4
  },
  gradientContainerOne:{
    width: "100%",
    justifyContent: 'space-between',
    top: '23%',
    boxShadow: '2px 2px 10px #363636',
    borderColor: '#e8971a',
    borderWidth: 3,
    borderRadius: 10
  },
  gradientContainerTwo:{
    position: 'fixed',
    backgroundColor: '#b7b7b7',
    padding: 5,
    borderRadius: 10,
    shadowColor: '#000',
    top: '22%'
  },
  inputStyle:{
    outline: 'none',
    width: '60%',
    color: '#000',
    fontFamily: fontFamily.font500,
    fontSize: 18
  },
  inputStyleOne:{
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent'
  },
  textStyleNew:{
    color: '#ffffff',
    fontSize: 300,
    fontFamily: 'Poppins_700Bold',
    opacity: 0.5
  },
  textStyleOne:{
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 20,
    textAlign: 'center',
    color: 'white'
  },
  textStyleTwo:{
    marginLeft: 15,
    fontSize: 16,
    color: '#fff',
    width: '90%'
  },
  textStyleThree:{
    marginLeft: 15,
    fontSize: 16,
    color: '#fff',
    width: '90%',
    marginTop: 10,
    marginBottom: 14
  },
  textStyleFour:{
    color: '#7891A9',
    textAlign: 'center',
    fontFamily: 'Poppins_800ExtraBold',
    fontSize: '23px'
  },
  textStyleFive:{
    fontFamily: 'Poppins_300Light',
    fontSize: 20,
    lineHeight: 30,
    marginTop: 28,
    marginLeft: '3%',
    color: " #000000",
  },
  notesText:{
    fontFamily: 'Poppins_300Light',
    fontSize: 20,
    lineHeight: 30,
    alignSelf: "stretch",
    textAlign: 'center'
  },
  notesTextOne:{
    fontFamily: fontFamily.font500,
    fontSize: 22,
    textAlign: 'left',
    color: '#1a1a1a',
    lineHeight: '100%'
  },
  noteButton:{
    borderWidth: 2,
    borderColor: '#1a1a1a',
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 2,
    alignSelf: 'flex-end'
  },
  noIDText:{
    color: '#67869B',
    fontSize: 22,
    fontWeight: 'bold',
    paddingHorizontal: 15,
    fontFamily: 'Poppins_700Bold'
  },
  saveButtonContainer:{
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'absolute',
    marginHorizontal: 10,
    zIndex: 2
  },
  saveButton:{
    marginRight: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    shadowColor: '#2bfac3',
    shadowOffset: {width: 0, height: 0.5},
    shadowRadius: 8,
    elevation: 5,
    shadowOpacity: 0.5
  },
  saveButtonText:{
    color: 'white',
    paddingHorizontal: 10,
    fontWeight: 'bold'
  },
  cancelIcon:{
    position: 'absolute',
    right: 10,
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
    height: '20%'
  },
  notesGradient : {
    backgroundColor: '#d3dfea',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 18,
    width: '50%',
    height: '37%',
    position: 'absolute',
    marginTop: '52px'
  },
  notesContainer:{
    backgroundColor: '#f8e96a',
    borderRadius: 10,
    marginTop: '2%'
  },
  listViewContainer:{
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: '#292929',
    textAlign: 'left',
    display: 'flex',
    borderRadius: 12,
    marginTop: 5
  },
  ScanText:{
    textAlign: 'center',
    fontSize: 60,
    color: '#FFFFFF',
    fontFamily: fontFamily.font300,
    lineHeight: 79,
    weight: 300,
    marginBottom: '2%',
  },
  scanInput:{
    flex: 1,
    margin: 5,
    borderRadius: 5,
    fontSize: 14,
    fontFamily: fontFamily.font400,
    padding: 5,
    paddingLeft: 15,
    backgroundColor: '#f4efef',
    color: '#54626F',
    borderColor: 'rgb(172,187,199)',
    borderWidth: 2,
    lineHeight: 21,
    width: '100%',
  },
  quickScanText:{
    color: 'white',
    fontFamily: fontFamily.font400,
    fontSize: 15
  },
  copyToClipBoard: {
    marginLeft: 25,
    width: 20,
    height: 20,
    tintColor: '#FFF'
  },
  reFreshText:{
    color: '#FFFFFF',
    fontFamily: fontFamily.font300,
    fontSize: 12,
    weight: 300,
    lineHeight: 18,
    textAlign: 'center',
  },
  searchContainer: {
    maxWidth: '353px',
    maxHeight: '80px',
    backgroundColor: 'rgba(157,176,193,0.3)',
    marginTop: 20,
    borderRadius: '10px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 22,
  },
  quickScanTextOne:{
    fontFamily: fontFamily.font400,
    fontSize: 14,
    color: '#030303'
  },
  quickScanTextOne:{
    fontFamily: fontFamily.font400,
    fontSize: 14,
    color: '#030303'
  },
  quickScanLoadingText:{
    color: 'white',
    fontFamily: fontFamily.font400,
    fontSize: 15,
    width: 220,
    textAlign: 'center'
  },
  quickScanTooltipContainer:{
    padding: 10,
    position: 'relative',
    marginTop: 10,
    borderRadius: 5,
    width: 353
  },
  quickScanTooltipContainerOne:{
    position: 'absolute',
    right: '8%',
    top: -10,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomColor: '#8d9fb1'
  },
  refreshTooltipContainerOne:{
    position: 'absolute',
    right: '11%',
    bottom: -10,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomColor: '#8d9fb1',
    transform: 'rotate(180deg)'
  },
  refreshTextTooltip:{
    padding: 10,
    position: 'absolute',
    zIndex: 99,
    bottom: 40,
    borderRadius: 5,
    width: 300,
    right: -60
  },
  quickScanContainer:{
    borderRadius: 5,
    marginTop: 30,
    borderWidth: 1,
    paddingHorizontal: 40,
    paddingVertical: 5,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3
  },
  quickScanContainer2:{
    borderRadius: 10,
    border: "2px solid #D7D7D7",
    marginTop: 30,
    paddingHorizontal: 11,
    paddingVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
    display: 'flex',
    flexDirection: 'row'
  },
  quickScanRfo:{
    borderRadius: 10,
    minWidth: 250,
    minHeight: 49,
    paddingLeft: 12,
    paddingRight: 12,
    border: "2px solid #D7D7D7"
  },
  importButtonRfo:{
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  lastImportText:{
    color: '#FFFFFF',
    fontFamily: fontFamily.font300,
    marginBottom: 7,
    fontSize: 16,
    textAlign: 'center'
  },
  lastImportTextOne:{
    color: '#FFFFFF',
    marginTop: 24,
    textAlign: 'center',
    fontFamily: fontFamily.font300,
    fontSize: 16,
    lineHeight: 20,
  },
  quickScanAlignment:{
    
    justifyContent: 'center',
    alignItems: 'center'
  },
  quickScanAlignmentOne:{
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
    alignItems: 'center'
  },
  quickScanImage:{
    height: 365 ,
    marginTop: 20,
    alignItems: 'center'
  },
  quickScanImageOne:{
    height: 24,
    width: 24,
    color: "white",
    marginTop: 12
  },
  packingCamContainer:{
    backgroundColor: '#000',
    height: 20,
    width: '100%',
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 10
    },
    shadowOpacity: 3,
    shadowRadius: 10,
    elevation: 5,
    marginLeft: -10
  },
  packingCamContainerOne:{
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#869db1',
    borderBottomWidth: 0,
    shadowColor: '#ffff',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.9,
    shadowRadius: 3,
    elevation: 3,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '80px',
    maxHeight: '80px'
  },
  packingCamEmailSent:{
     justifyContent: 'center',
      alignItems: 'center',
      top: '40px',
      position: 'absolute',
      zIndex: 999,
      width: 'max-content',
      left: 0,
      right: 0,
      marginLeft:'auto',
      marginRight: 'auto',
      minWidth: '250px'
  },
  scrollViewContainer:{
    flexDirection: 'row',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    paddingTop: '2%',
    flexWrap: 'wrap',
    paddingLeft: 30,
    backgroundColor: 'rgba(15, 39, 58, 0.4)',
    margin: 5,
    paddingBottom: '10%'
  },
  imageContainer:{
    marginRight: 30,
    marginBottom: 30,
    borderRadius: 15,
    overflow: 'hidden'
  },
  packingCamText:{
    height: 100,
    alignSelf: 'center',
    paddingTop: 10,
    color: '#dbd7d7',
    fontFamily: fontFamily.font300,
    fontSize: '23px'
  },
  packingCamTextOne:{
    color: 'white',
    alignSelf: 'center',
    fontFamily: 'Poppins_600SemiBold'
  },
  packingCamTextTwo:{
    color: 'white',
    marginLeft: 10
  },
  packingCamTextContainer:{
    marginHorizontal: 20,
    marginVertical: 20,
    backgroundColor: 'rgba(104, 135, 156, 0.4)',
    borderRadius: 10
  },
  packingCamTextContainerOne:{
    flexDirection: 'row',
    marginTop: 18
  },
  packingCamIcon:{
    color: 'white',
    height : 17,
    width:17
  },
  packingCamModalVisible:{
    flex: 1,
    position: 'absolute',
    alignItems: 'center',
    top: 20,
    width: '100%'
  },
  packingCamRemoveImage:{
    flex: 1,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    opacity: 0.5
  },
  alignmentContainer:{
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2%',
    width: '100%'
  },
  notes: {
    width: '96%',
    alignSelf: 'center',
    // height: 0,
    border: "1px solid #ECDC62",
    backgroundColor: '#ECDC62'
  },
  flexStyle:{
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '5%'
  },
  textStyle:{
    fontFamily: "Poppins_300Light",
    marginTop: 5,
    textAlign: 'center'
  },
  enterScanStyle:{
    fontFamily: 'Poppins_300Light',
    color: "#8A8A8A",
    flex: "none",
    order: 0,
    alignSelf: "stretch",
    flexGrow: 0
  },
  checkInputStyle:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  responseViewNotes:{
    width: '96%',
    alignSelf: 'center',
    height: 2,
    // padding: 5,
    backgroundColor: '#eddd63'
  },
  notesMainStyle:{
    backgroundColor: '#f8e96a',
    borderRadius: 10
  },
  orderConfirmationText:{
    fontFamily: 'Poppins_300Light',
    alignSelf: "stretch",
    textAlign: 'center'
  },
  cancelIcon:{
    marginTop: 9,
    marginLeft: "93%",
    fontSize: "26px",
  }, // Product confirmations styles
  productConfirmation:{
    backgroundColor: '#d3dfea',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 18,
  },
  productInstruction:{
    fontFamily: 'Poppins_300Light',
    fontSize: 20,
    lineHeight: 30,
    marginTop: 28,
    marginLeft: '3%',
  },
  productConfirmationTest:{
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 20,
    lineHeight: 20,
    marginTop: 10,
    textAlign: 'center',
    justifyContent: 'center'
  },
  trackingNumberText:{
    color: '#7891A9',
    textAlign: 'center',
  },
  viewStyle:{
    justifyContent: 'center',
    width: '100%'
  },
  passButtonStyle:{
    flex: 4,
    marginLeft: 10,
    backgroundColor: '#455766',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 7,
    borderRadius: 5
  },
  nextItemText:{
    fontFamily: fontFamily.font500,
    fontSize: 22,
    textAlign: 'left',
    color: '#1a1a1a'
  },
  nextItemRowFlexMobile:{
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: '#292929',
    textAlign: 'left',
    display: 'flex'
  },
  nextItemNotes:{
    width: '100%',
    height: 1,
    backgroundColor: 'black'
  },
  passButtonText:{
    fontFamily: fontFamily.font800,
    fontSize: 26,
    color: '#fff',
    textAlign: 'center'
  },
  nextItemRowFlex:{
    flexDirection: 'row',
    position: 'relative',
    display: 'flex'
  },
  nextItemCloseNote:{
    borderWidth: 2,
    borderColor: '#1a1a1a',
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 2,
    alignSelf: 'flex-end'
  },

  orderSearchBackImg:{
    height: 365,
    marginTop: 20,
    alignItems: 'center'
  },
  orderSearchScan:{
    textAlign: 'center',
    fontSize: 60,
    color: '#8da4b8',
    fontFamily: fontFamily.font400,
    marginTop: 184
  },
  searchOrderInput:{
    backgroundColor: 'black',
    borderWidth: 2,
    borderColor: '#7a92a8',
    width: 250,
    borderRadius: 5,
    color: 'white',
    fontSize: 16,
    fontFamily: fontFamily.font400,
    outline: 'none',
    padding: 5
  },
  searchOrderText:{
    color: '#8da4b8',
    fontSize: 18,
    marginTop: 24,
    fontFamily: fontFamily.font400,
    textAlign: 'center'
  },

  orderListStyle:{
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    marginTop: 10,
    height: 40,
    flexWrap: 'wrap',
    paddingHorizontal: 15
  },
  typeOrderInput:{
    backgroundColor: '#f4efef',
    borderRadius: 5,
    height: 30,
    paddingHorizontal: 10,
    flexDirection: 'row',
    width: '90%',
    marginLeft: 30
  },
  quickScanToolTip:{
    padding: 10,
    position: 'relative',
    marginTop: 10,
    borderRadius: 5,
    width: 300,
    alignSelf: 'flex-end'
  },
  quickScanToolTip2:{
    padding: 10,
    alignSelf: 'flex-end',
    borderRadius: 5,
    width: 335,
    flexWrap: 'wrap'
  },
  orderListWeb:{
    flex: 1,
    backgroundColor: 'rgba(15, 39, 58, 0.4)',
    borderRadius: 15,
    marginBottom: 50,
    minHeight: 200,
    marginTop: 10,
    paddingLeft: 10,
    width: '97%',
    marginLeft: 23,
    flexWrap: 'wrap'
  },
  itemContainerOrderList:{
    flexDirection: 'row',
    paddingHorizontal: '1%',
    width: '100%',
    flexWrap: 'wrap'
  },
  circleStyle:{
    width: '95%',
    flexDirection: 'row',
    borderBottomWidth: 0.7,
    borderColor: 'white'
  },
  orderListMobile:{
    flex: 1,
    marginHorizontal: 10,
    backgroundColor: 'rgba(15, 39, 58, 0.4)',
    borderRadius: 15,
    marginBottom: 50,
    minHeight: 200,
    marginTop: 10,
    flexWrap: 'wrap'
  },
  iconSearch:{
    color: 'rgb(172,187,199)',
    marginTop: '34%',
    marginLeft: -50,
    fontSize: '19px'
  },
  searchItemButton:{
    flexDirection: 'row',
    marginLeft: 10,
    marginTop: '5.5px'
  },

  orderInfoStyle:{
    paddingHorizontal: '1%',
    borderRadius: 10,
    marginHorizontal: '1%',
    marginTop: 20,
    marginBottom: 10,
    shadowColor: '#50708b',
    shadowOffset: {width: 1, height: 1},
    shadowRadius: 10
  },
  notesToPackerTooltip:{
    padding: 10,
    position: 'absolute',
    zIndex: 99,
    marginTop: 10,
    borderRadius: 5,
    width: 300,
  },
  noteViewStyle:{
    position: 'absolute',
    top: -10,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomColor: '#8d9fb1'
  },
  confirmationRequiredStyle:{
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    margin: 5
  },
  confirmationRequiredText:{
    fontWeight: 'bold',
    fontSize: 18,
    color: '#dbdada',
    marginTop: 8
  },
  alwaysTextStyle:{
    width: 60,
    padding: 2,
    fontWeight: 'bold',
    justifyContent: 'center',
    textAlign: 'center'
  },
  notesToolTipStyle:{
    color: 'rgb(172,187,199)',
    fontSize: '17px',
    marginLeft: '35%'
  },
  notesFromPackerStyle:{
    padding: 10,
    position: 'absolute',
    right: '-1%',
    marginTop: 10,
    borderRadius: 5,
    width: 335,
    alignSelf: 'flex-end',
    zIndex: 999,
    top: 35
  },

  orderDetailStyle:{
    width: '100%',
    zIndex: -1,
    position: 'absolute'
  },

  saveItemToOrderStyle:{
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
    flexDirection: 'row'
  },
  saveItemButtonStyle:{
    color: 'white',
    paddingHorizontal: 10,
    fontWeight: 'bold'
  },

  itemDetailStyle:{
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  itemDetailAddItem:{
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 30,
  },
  itemDetailItemsStyle:{
    flex: 1,
    backgroundColor: 'rgba(15, 39, 58, 0.4)',
    borderRadius: 15,
    marginBottom: 50,
    height: 100
  },
  itemDetailHeading:{
    flexDirection: 'row',
    paddingHorizontal: '1%',
    width: '96%',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  qtyOrderStyle:{
    width: '10%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemDetailUrlImage:{
    flexDirection: 'row',
    borderBottomWidth: 0.7,
    borderColor: 'rgba(250, 250, 250, .4)',
    paddingHorizontal: '1%',
    width: '96%',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  updateQtyStyle:{
    width: 25,
    height: 25,
    fontSize: 19,
    color: 'white'
  },
  itemDetailMobile:{
    flex: 1,
    marginHorizontal: 10,
    backgroundColor: 'rgba(15, 39, 58, 0.4)',
    borderRadius: 15,
    marginBottom: 50,
    paddingVertical: 15,
    paddingLeft: 10,
    flexWrap: 'wrap'
  },
  itemDetailProductName:{
    borderBottomWidth: 0.7,
    borderColor: 'rgba(250, 250, 250, .4)',
    paddingHorizontal: '2%',
    width: '95%',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  multiBoxDropDown:{
    height: 30,
    width: 300,
    background: 'transparent',
    borderTopWidth: 0,
    borderbottomcolor: 'rgba(255, 255, 255)',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    color: '#fff',
    fontSize: 18,
    borderColor: 'white'
  },
  InfoDetailTextStyle:{
    fontFamily: 'Poppins_600SemiBold',
    color: '#dbdada',
    fontSize: 20,
    marginTop: 10
  },
  InfoDetailTextStyleSecond:{
    color: 'white',
    fontSize: 20,
    marginRight: 2,
    fontFamily: 'Poppins_600SemiBold'
  },
  infoDetailInputStyle:{
    backgroundColor: '#dbdada',
    fontFamily: 'Poppins_500Medium',
    fontSize: 15
  },
  multiBoxScroll:{
    backgroundColor: "rgba(15, 39, 58, 0.4)",
    width: "93%",
    marginLeft: "3%",
    height: "auto",
    borderRadius: 11
  },
  multiBoxItemStyle:{
    flex: 1,
    marginHorizontal: 10,
    backgroundColor: 'rgba(15, 39, 58, 0.4)',
    borderRadius: 15,
    marginBottom: 50,
    paddingVertical: 15,
    paddingLeft: 10,
    flexWrap: 'wrap'
  },
  multiBoxProductName:{
    borderBottomWidth: 0.7,
    borderColor: 'rgba(250, 250, 250, .4)',
    paddingHorizontal: '2%',
    width: '95%',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    paddingBottom: 15
  },
  multiBoxFlex:{
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start'
  },
  multiBoxItemView:{
    alignSelf: 'flex-end',
    paddingHorizontal: '2%',
    width: '30%',
    alignItems: 'flex-end',
    paddingVertical: 5
  },
  multiBoxImage:{
    width: 75,
    height: 75,
    borderRadius: 7
  },
  multiBoxScrollWeb:{
    backgroundColor: 'rgba(15, 39, 58, 0.4)',
    width: '16%',
    height: 500,
    borderRadius: 13
  },
  multiBoxItemContainer:{
    backgroundColor: 'rgba(15, 39, 58, 0.4)',
    width: '82%',
    marginLeft: 27,
    height: 500,
    borderRadius: 13
  },
  multiBoxItemsView:{
    flexDirection: 'row',
    borderBottomWidth: 0.7,
    borderColor:
      'rgba(250, 250, 250, .4)',
    paddingHorizontal: '1%',
    width: '96%',
    paddingTop: '2%',
    paddingBottom: '2%',
    // paddingTop:"2%",
    paddinBottom: '220%',
    justifyContent: 'space-around',
    // backgroundColor: 'aqua',
    flexWrap: 'wrap'
  },
  multiBoxWebBoxes:{
    color: '#EAEFF2',
    fontFamily: 'Poppins_400Regular',
    fontSize: 20
  },
  activityView:{
    flex: 1,
    backgroundColor: 'rgba(15, 39, 58, 0.4)',
    borderRadius: 15,
    marginBottom: 50,
    width: '50%'
  },
  addItemToOrderContainer:{
    flexDirection: 'row',
    paddingHorizontal: '2%',
    width: '100%',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  addItemContainer:{
    marginTop: 0,
    alignSelf: 'center',
    flexDirection: 'row',
    marginBottom: 10
  },
  toOrderLinkView:{
    marginTop: 0,
    justifyContent: 'center',

    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10
  },
  addItemHeader:{
    width: '95%',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  activitiesButtonStyle:{
    height: 28,
    borderRadius: 1,
    padding: 5,
    borderRadius: 10,
    position: 'absolute',
    right: '0%',
    top: '8%'
  },
  activitiesAlertStyle:{
    justifyContent: 'center',
    alignItems: 'center',
    width: '35%',
    marginLeft: '34%',
    top: '-7%',
    zIndex : 9,
    position:"relative"
  },
  activitiesAlertMobileStyle:{
    width: '100%',
    zIndex : 9,
    position:"relative"
  },
  activitiesTextStyle:{
    color: '#cdcdcd',
    fontFamily: 'Poppins_800ExtraBold',
    fontSize: '16px'
  },
  activitiesTextStyleSecond:{
    marginBottom: 8,
    color: '#dbdada',
    fontFamily: 'Poppins_400Regular',
    fontSize: '15px'
  },
  activitiesTextStyleThree:{
    fontFamily: 'Poppins_400Regular',
    color: '#dbdada',
    fontSize: '20px',
    textAlign: 'center'
  },
  activitiesTextStyleFour:{
    fontFamily: 'Poppins_400Regular',
    textAlign: 'left',
    marginLeft: '5%',
    fontSize: 14,
    color: '#fff'
  },
  userListStyle:{
    top: '-1%',
    left: '-5%',
    width: 221,
    backgroundColor: 'rgba(45, 68, 95,1)',
    borderRadius: 12
  },
  exceptionStyle:{
    borderRadius: 5,
    width: '65%',
    marginTop: 30,
    marginLeft: '18%',
    borderWidth: 1,
    paddingHorizontal: 40,
    paddingVertical: 5,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3
  },
  exceptionTextStyle:{
    fontFamily: 'Poppins_400Regular',
    color: 'white',
    fontSize: '15px'
  },
  exceptionTextStyleTwo:{
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: '#fff',
    marginLeft: 10
  },
  activeButtons:{
    width: '50%',
    marginTop: '-4%',
    justifyContent: 'center',
    textAlign: 'center'
  },
  associatedTextStyle:{
    fontFamily: 'Poppins_400Regular',
    color: 'white',
    fontSize: '15px',
    marginLeft: -13
  },
  activitiesMainTextStyle:{
    flexDirection: 'row',
    backgroundColor: '#b7b7b7',
    padding: 5,
    borderRadius: 10,
    width: '100%',
    marginLeft: '',
    marginRight: '',
    padding: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 10,
    shadowRadius: 10
  },
  activitiesExpFlex:{
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20
  },
  textFileButton:{
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: '50%'
  },
  ItemDetailAddItemText:{
    fontFamily: 'Poppins_800ExtraBold',
    color: 'white',
    fontSize: 16,
    textShadowColor: '#000',
    textShadowOffset: {width: 1, height: 1},
    textShadowRadius: 5,
    right: '20%',
    width: '100%',
    marginLeft: 2,
    elevation: 5
  },
  imageStyle:{
    width: 35,
    height: 35,
    resizeMode: 'stretch'
  },
  imageStyleForGrid:{
    width: 15,
    height: 15,
    resizeMode: 'stretch'
  },
  imageStyle2:{
    width: 70,
    height: 70,
    borderRadius: 5
  },
  imageStyle3:{
    width: 30,
    height: 30,
    resizeMode: 'contain'
  },
  imageStyle4:{
    width: 75,
    height: 75,
    borderRadius: 7
  },
  itemDetailItemView:{
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start'
  },
  itemDetailFlex:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  itemName:{
    width: '15%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  qtyStyle:{
    width: '4%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemsDetailMain:{
    width: '2%',
    marginHorizontal: '1%',
    paddingVertical: 15
  },
  activityContainer:{
    flexDirection: 'row',
    marginLeft: 20,
    marginTop: 15
  },
  logStyle:{
    color: 'white',
    marginBottom: 15,
    fontSize: 15
  },
  orderButton:{
    fontSize: 21,
    fontFamily: 'Poppins_600SemiBold',
    color: 'white'
  },
  exceptionType:{
    fontFamily: 'Poppins_400Regular',
    color: '#dbdada',
    fontSize: '20px'
  },
  roundedBox:{
    backgroundColor: '#dbdada',
    width: '95%',
    padding: '6px',
    fontFamily: 'Poppins_500Medium',
    fontSize: 15
  },
  infoDetailFlex:{
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  QtyOrdered:{
    width: '18%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  multiBoxProductNameSecond:{
    flexDirection: 'row',
    // borderBottomWidth: 0.7,
    // borderColor: "rgba(250, 250, 250, .4)",
    paddingHorizontal: '1%',
    paddingVertical: 15,
    width: '96%',
    justifyContent: 'space-around',
    // backgroundColor: 'aqua',
    flexWrap: 'wrap'
  },
  orderTooltipBar:{
    position: 'absolute',
    right: '7%',
    top: -10,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomColor: '#8d9fb1'
  },
  rdgHeader: {
    zIndex: 1,
    marginBottom: '-28px',
    backgroundColor: '#262B30',
    width: '98%',
    alignSelf: 'center',
    height: '2rem',
    flexDirection: 'row',
    paddingHorizontal: 20,
    justifyContent: 'flex-start'
  }

})

export default styles;
