import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  // **Shared Styles for Tag Views**

  tagSection: {
    display: 'flex',
    backgroundColor: '#262B2F',
    marginHorizontal: 'auto',
    width: '100%',
    borderRadius: 10
  },
  tagHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 3,
    backgroundColor: '#1B1D1F',
    marginTop: '5%'
  },
  tagHeaderText: {
    marginLeft: '5%',
    color: 'white'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 'auto',
    paddingHorizontal: '2%',
    width: '94%',
    marginTop: '3%'
  },
  searchInput: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 8,
    width: '90%'
  },
  icon: {
    fontSize: 25,
    color: '#fff',
    marginRight: '5%'
  },
  addIcon: {
    fontSize: 15,
    color: '#fff',
    marginRight: '5%'
  },
  tagListContainer: {
    marginTop: '2%',
    marginLeft: '5%',
    marginBottom: '2%',
    height: '20vh'
  },

  // **Shared Styles for Tag Items**

  tagContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5
  },
  tagWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  selectedTagWrapper: {
    width: '90%',
    padding: 5,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#808080'
  },
  tagText: {
    color: 'white',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 16,
    maxWidth: '80%'
  },
  cancelIcon: {
    fontSize: 15,
    color: 'gray'
  },

  // **Unique Styles for OrderTagsView**

  divider: {
    width: '96%',
    backgroundColor: '#ffffff',
    height: 1,
    alignSelf: 'center',
    marginVertical: 10,
    marginLeft: '-18px'
  },
  addNewTagContainer: {
    alignSelf: 'center',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },

  // **Unique Styles for OrderTags**

  colorBar: {
    width: 5,
    height: 20,
    borderRadius: 5,
    marginLeft: 10
  }
});

export default styles;
