import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import reduxThunk from 'redux-thunk';

import scanpackReducer from '../reducers/scanpackReducer';
import updateUrlReducer from '../reducers/updateUrlReducer';
import productReducer from '../reducers/productReducer';
import orderReducer from '../reducers/orderReducer';
import userReducer from '../reducers/userReducer';
import updateAsyncReducer from '../reducers/updateAsyncReducer';
import saveLogReducer from '../reducers/saveLogReducer';
import headerReducer from '../reducers/headerReducer';
import menuReducer from '../reducers/menuReducer ';
import importorderReducer from '../reducers/importorderReducer';
import orderLoaderReducer from '../reducers/orderLoaderReducer';
import orderTagPriorityReducer from '../reducers/tagPriorityReducer';

const rootReducer = combineReducers({
  scanpack: scanpackReducer,
  updateUrl: updateUrlReducer,
  product: productReducer,
  order: orderReducer,
  user: userReducer,
  updateAsync: updateAsyncReducer,
  saveLog: saveLogReducer,
  header: headerReducer,
  menu: menuReducer,
  importOrder: importorderReducer,
  orderLoader:orderLoaderReducer,
  tagPriority:orderTagPriorityReducer
});

const middleware = applyMiddleware(reduxThunk);
const enhancer = compose(middleware);

const configureStore = preloadedState => {
  return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
