import React from 'react';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {ToggleAddTag} from '../../../actions/tagPriorityActions';

export const AddTagButton = ({title}) => {
  const dispatch = useDispatch();
  const {displayAddTag} = useSelector(state => state.tagPriority);
  const handleOnPress = () => {
    dispatch(ToggleAddTag(!displayAddTag));
  };

  return (
    <View style={styles.addTagSection}>
      <TouchableOpacity testID="addTagButton" onPress={handleOnPress}>
        <i className="icon-plus" style={styles.iconPlus}></i>
      </TouchableOpacity>
      <Text style={styles.addTagText}>{title}</Text>
      {title !== 'Add user card' && (
        <View style={styles.standByContainer}>
          <Text style={[styles.addTagText, {textAlign: 'center'}]}>
            Stand by List
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  addTagSection: {
    display: 'flex',
    alignItems: 'center',
    marginVertical: '2%'
  },
  iconPlus: {
    color: '#979B9F',
    fontSize: 40,
    marginTop: 10
  },
  addTagText: {
    color: '#D1D2D4',
    fontSize: 16,
    fontWeight: 'bold'
  },
  standByContainer: {
    width: '100%',
    backgroundColor: '#262B31',
    padding: 5,
    marginTop: 5
  }
});
