import React, { useEffect, useState } from 'react'
import ListItem from '../../../commons/dropDowns/orderTags/listItem';
import { StyleSheet, View } from 'react-native';
import CustomScrollBar from '../../../commons/customScrollBar';

export const UpdateOrderTag = ({
  groovePackerTags,
  otherTags,
  newEditingItem
}) => {
  const [editingItem, setEditingItem] = useState();
  const [editingItemId, setEditingItemId] = useState();
  const [isPickerModalVisible, setIsPickerModalVisible] = useState(false);

  useEffect(() => {
    if (newEditingItem) {
      setEditingItem(newEditingItem);
      setEditingItemId(newEditingItem.id)
    }
  }, [newEditingItem]);

  const handleEditTag = item => {
  };

  return (
    <CustomScrollBar>
      <View style={{width: '100%'}}>
        {groovePackerTags.map(item => (
          <ListItem
            key={item.id}
            item={item}
            setEditingItemId={setEditingItemId}
            editingItemId={editingItemId}
            onEdit={handleEditTag}
            setIsPickerModalVisible={() => {}}
            maxWidth={'90%'}
          />
        ))}
      </View>
      <View style={styles.divider} />
      <View>
        {otherTags.map(item => (
          <ListItem
            key={item.id}
            item={item}
            setEditingItemId={setEditingItemId}
            editingItemId={editingItemId}
            onEdit={handleEditTag}
            setIsPickerModalVisible={() => {}}
            maxWidth={'90%'}
          />
        ))}
      </View>
    </CustomScrollBar>
  );
};

const styles = StyleSheet.create({
  divider: {
    width: '106%',
    backgroundColor: '#ffffff',
    height: 1,
    alignSelf: 'center',
    marginVertical: 10,
    marginLeft: '-18px'
  }
});
