import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import styles from './styles';

export const OrderTags = ({orderTags, selectedTag, setSelectedTag}) => {
  return (
    <View>
      {orderTags?.map((tag, index) => (
        <View key={index} style={styles.tagContainer}>
          <TouchableOpacity
            testID={`${tag.name}_Id`}
            onPress={() => setSelectedTag(tag)}
            style={[
              styles.tagWrapper,
              selectedTag === tag && styles.selectedTagWrapper
            ]}>
            <Text style={styles.tagText} numberOfLines={1}>
              {tag.name}
            </Text>
            <View
              style={[
                styles.colorBar,
                {
                  backgroundColor: tag.color,
                  boxShadow: `0px 0px 4px 1px ${tag.color}`
                }
              ]}
            />
          </TouchableOpacity>
          {selectedTag === tag && (
            <TouchableOpacity
              testID="RemoveSelectedTag"
              onPress={() => setSelectedTag(null)}>
              <i className="icon-cancel" style={styles.cancelIcon}></i>
            </TouchableOpacity>
          )}
        </View>
      ))}
    </View>
  );
};
