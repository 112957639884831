import React from 'react';
import {View, TextInput} from 'react-native';
import CustomScrollBar from '../../../commons/customScrollBar';
import {UserList} from './userList';
import styles from './styles';
import {getFilteredUserTags} from './helperFunctions';

export const UserTagsView = ({
  searchQuery,
  setSearchQuery,
  users,
  selectedTag,
  handleTagUpdate,
  isFirstLoad
}) => {
  const {filteredUserTags, selectedTagList} = getFilteredUserTags(
    users,
    selectedTag,
    isFirstLoad
  );

  return (
    <View style={styles.tagSection} testID="userTagContainer">

      <View style={styles.tagHeader} />

      <View style={styles.searchContainer}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search user..."
          value={searchQuery}
          onChangeText={setSearchQuery}
        />
        <i className="icon-search" style={styles.icon}></i>
      </View>

      <CustomScrollBar>
        <View style={styles.tagListContainer}>
          <UserList
            users={selectedTagList}
            selectedUser={selectedTag}
            setSelectedUser={handleTagUpdate}
          />
          <UserList
            users={filteredUserTags}
            selectedUser={selectedTag}
            setSelectedUser={handleTagUpdate}
          />
        </View>
      </CustomScrollBar>

    </View>
  );
};
