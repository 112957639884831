import React from 'react';
import {
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  StyleSheet
} from 'react-native';
import {CreatePriorityTag} from './createPriorityTag';

export const AddPriorityTagModal = ({isVisible, onClose, editingItem, isUserTag}) => {
  return (
    <Modal
      visible={isVisible}
      animationType="fade"
      transparent={true}
      onRequestClose={onClose}>
      <TouchableOpacity
        style={styles.overlay}
        activeOpacity={1}
        onPress={onClose}>
        <TouchableWithoutFeedback>
          <View style={styles.modalContent}>
            <CreatePriorityTag
              setModalVisible={onClose}
              editingItem={editingItem}
              isUserTag={isUserTag}
            />
          </View>
        </TouchableWithoutFeedback>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 0.5,
    borderRadius: 10,
    alignItems: 'center'
  }
});
