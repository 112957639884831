import {LinearGradient} from 'expo-linear-gradient';
import React, {useEffect, useRef, useState} from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator
} from 'react-native';
import CommonStyles from '../../../style/commonStyles';
import OrderTagsContainer from './orderTagsContainer';
import {useDispatch} from 'react-redux';
import {
  CreateTagPriority,
  CreateUserTagPriority,
  DeleteTagPriority,
  GetTagPriorityList,
  UpdateTagPriority
} from '../../../actions/tagPriorityActions';
import i18n from 'i18n-js';

export const CreatePriorityTag = ({
  setModalVisible,
  editingItem,
  isUserTag
}) => {
  const dispatch = useDispatch();
  const [displayName, setDisplayName] = useState(editingItem?.priority_name || '');
  const [tag, setTag] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSave = () => {
    setIsLoading(true);
    const payload = isUserTag ? getUserTagPayload() : getOrderTagPayload();
    if (editingItem) {
      dispatch(UpdateTagPriority(editingItem.id, payload, callBack));
      return;
    }
    const action = isUserTag ? CreateUserTagPriority : CreateTagPriority;
    dispatch(action(payload, callBack));
  };

  const getOrderTagPayload = () => {
    const priorityCard = {
      priority_name: displayName || tag.name,
      tag_color: tag.color,
      is_card_disabled: false,
      assigned_tag: tag.name,
      position: editingItem ? editingItem.position : 1,
      id: editingItem ? editingItem.id : null,
      is_favourite: editingItem ? editingItem.is_favourite : false
    };
    return {
      priority_card: priorityCard
    };
  };

  const getUserTagPayload = () => ({
    username: tag.username,
    priority_card: {
      priority_name: displayName || tag.username,
      tag_color: '#AAAAAA',
      is_card_disabled: false,
      assigned_tag: tag.username
    }
  });

  const callBack = errorMessage => {
    setError(errorMessage || '');
    setIsLoading(false);
    if (!errorMessage) {
      setModalVisible(false);
      dispatch(GetTagPriorityList());
    };
  };

  const handleDelete = () => {
    if (editingItem) {
      dispatch(DeleteTagPriority(editingItem.id, deleteCallBack));
    } else {
      setModalVisible();
    }
  };
  const deleteCallBack = errorMessage => {
    if (errorMessage) {
      setError(errorMessage);
      setIsLoading(false);
      return;
    }
    dispatch(GetTagPriorityList());
    setIsLoading(false);
    setModalVisible(false);
  };

  return (
    <LinearGradient
      colors={['#27374B', '#526B82']}
      start={{x: 0, y: 0}}
      end={{x: 1, y: 0}}
      style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.displayNameContainer}>
          <Text style={styles.displayText}>Display Name</Text>
          <TextInput
            ref={inputRef}
            value={displayName}
            onChangeText={text => setDisplayName(text)}
            placeholder="Leave blank to use tag name"
            placeholderTextColor={'#898989'}
            style={styles.displayInput}
          />
        </View>
        <View style={styles.infoContainer}>
          <Text style={[styles.description, {width: '100%', textAlign: 'center'}]}>
            {i18n.t('tagPriority.createPriorityCardText')}
          </Text>
        </View>
        <OrderTagsContainer
          setTag={setTag}
          editingTagName={editingItem?.assigned_tag}
          isUserTag={isUserTag}
        />
        {error.length > 0 && <Text style={styles.errorText}>{error}</Text>}
        <TouchableOpacity
          testID="saveButton"
          onPress={handleSave}
          style={[
            CommonStyles.saveButton,
            styles.saveChangeButton,
            {marginTop: 30}
          ]}
          disabled={!tag}>
          {isLoading ? (
            <ActivityIndicator color="#fff" />
          ) : (
            <Text style={CommonStyles.saveButtonText}>Save & Close</Text>
          )}
        </TouchableOpacity>

        <TouchableOpacity testID='deleteButton' style={styles.deleteButton} onPress={handleDelete}>
          <Text style={{color: 'white'}}>{isUserTag ? 'Delete this user card': 'Delete this Priority'}</Text>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#546D85',
    width: 'fitContent',
    padding: 10,
    borderRadius: 5,
    alignSelf: 'flex-start'
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10%',
    justifyContent: 'center'
  },
  displayNameContainer: {
    display: 'flex',
    width: '100%',
    marginHorizontal: 'auto',
    paddingHorizontal: '10%'
  },
  displayText: {
    color: '#fff',
    fontSize: 16,
    marginLeft: '7%',
    paddingBottom: 10
  },
  displayInput: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 8
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10
  },
  description: {
    color: '#ccc',
    fontSize: 14,
    marginBottom: 20
  },
  saveButton: {
    backgroundColor: '#333355',
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    marginBottom: 10
  },
  saveButtonText: {
    color: '#fff',
    fontSize: 16
  },
  saveChangeButton: {
    borderWidth: 2,
    shadowColor: '#E853FA',
    shadowOffset: {width: 0, height: 0.5},
    shadowRadius: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingVertical: 5,
    paddingHorizontal: 7
  },
  deleteButton: {
    display: 'flex',
    marginHorizontal: 'auto',
    backgroundColor: '#9D0200',
    padding: 15,
    borderRadius: 25,
    alignItems: 'center',
    marginTop: 30,
    borderWidth: 2,
    borderColor: '#BB1C15'
  },
  errorText: {
    color: 'red',
    marginTop: 10,
    textAlign: 'center'
  }
});
