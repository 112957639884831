export const lightenColor = (color, percentage = 30) => {
  const decimalPercentage = percentage / 100;
  const colorValue = parseInt(color.slice(1), 16);
  const r =
    (colorValue >> 16) +
    Math.round((255 - (colorValue >> 16)) * decimalPercentage);
  const g =
    ((colorValue >> 8) & 0x00ff) +
    Math.round((255 - ((colorValue >> 8) & 0x00ff)) * decimalPercentage);
  const b =
    (colorValue & 0x0000ff) +
    Math.round((255 - (colorValue & 0x0000ff)) * decimalPercentage);

  return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
};
