import React, { useEffect, useRef, useState } from 'react';
import { Text, View, TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import CommonStyles from '../../style/commonStyles';
import { LinearGradient } from 'expo-linear-gradient';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const AssignOrdersButton = ({setFocus}) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const user = useSelector(state => state.user.userInfo);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [inputText, setInputText] = useState('15');

  const handleAssignOrder = async () => {
    try {
      // dispatch(orderAssignment());
      const storageKey = getStorageKey();
      if (storageKey) {
        await AsyncStorage.setItem(storageKey, inputText);
      }
    } catch (error) {
      console.error('Error saving value:', error);
    }
  };

  const getStorageKey = () => {
    return user?.current_tenant
      ? `assignOrdersCount.${user.current_tenant}`
      : null;
  };

  useEffect(() => {
    const loadSavedValue = async () => {
      try {
        const storageKey = getStorageKey();
        if (storageKey) {
          const savedValue = await AsyncStorage.getItem(storageKey);
          if (savedValue) {
            setInputText(savedValue);
          }
        }
      } catch (error) {
        console.error('Error loading saved value:', error);
      }
    };

    loadSavedValue();
  }, [user?.current_tenant]);

  return (
    <View style={[CommonStyles.quickScanAlignmentOne, styles.container]}>
      <View style={styles.row}>
        <LinearGradient colors={['#95abbf', '#516b83']} style={CommonStyles.quickScanRfo}>
          <TouchableOpacity
            testID="quickSearch"
            style={styles.button}
            dataSet={{ componentName: 'quickSearch' }}
            onPress={handleAssignOrder}>
            <View style={styles.buttonContent}>
              <Text testID="quickScan" style={CommonStyles.quickScanText}>
                Assign
              </Text>
              <TextInput
                ref={inputRef}
                style={styles.input}
                autoFocus={false}
                onFocus={()=>{setFocus(false)}}
                value={inputText}
                onChangeText={text => setInputText(text)}
                onSubmitEditing={()=>{setFocus(true)}}
                onBlur={()=>{setFocus(true)}}
              />
              <Text testID="quickScan" style={CommonStyles.quickScanText}>
                Orders
              </Text>
            </View>
            <i className="icon-right-circled" style={styles.icon} />
          </TouchableOpacity>
        </LinearGradient>

        <TouchableOpacity
          testID="tooltipFun"
          onPress={() => setTooltipVisible(!tooltipVisible)}>
          <i className="icon-info-circled" style={styles.tooltipIcon} />
        </TouchableOpacity>
      </View>

      <View>
        {tooltipVisible && (
          <LinearGradient
            testID="quickScanToolTip"
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={CommonStyles.quickScanTooltipContainer}>
            <Text style={CommonStyles.quickScanTextOne}>
              {i18n.t('scanPack.assignOrderTooltip')}
            </Text>
            <View style={[CommonStyles.quickScanTooltipContainerOne, styles.tooltipContainer]} />
          </LinearGradient>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContent: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  input: {
    height: 25,
    width: 35,
    backgroundColor: '#3A3B3B',
    borderRadius: 10,
    textAlign: 'center',
    color: '#ccc',
    marginHorizontal: 5,
    borderColor: '#717374',
    borderWidth: 1,
  },
  icon: {
    fontSize: 24,
    color: 'white',
    marginTop: 12,
  },
  tooltipIcon: {
    color: 'rgb(119,143,165)',
    fontSize: 24,
    marginLeft: 10,
    marginTop: 12,
  },
  tooltipContainer: {
    right: '10%',
  },
});



