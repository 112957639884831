import React, {Component} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  TextInput,
  ScrollView
} from 'react-native';
import Activities from './activities';
import Exception from './exception';
import styles from '../../style/orderdetail';
import globalStyles from '../../style/global';
import moment from 'moment';
import AlertBoxPermission from '../../commons/alertBoxPermission';
import {LinearGradient} from 'expo-linear-gradient';
import OrderInfoHeader from '../scanpack/order-info-header';
import ActivitiesButtons from './activitiesButtons';
import CommonStyles from '../../style/commonStyles';
let exceptionIndex = 0;
let orderExceptionPop = false;
let clearExceptionPop = false;
let desc = true;
export default class ActivitiesExceptionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activitiesStatus: true,
      exceptionStatus: false,
      descriptionText: '',
      userState: '',
      checked: '',
      windowWidth: Dimensions.get('window').width,
      dropDownToggle: false,
      userSelectState: '',
      optionState: {},
      alertShow: false,
      errorExceptionAlert: false,
      clearRecordAlert: false,
      clearRecordAlertError: false,
      initialSelected: false,
      permissionState: false
    };
  }

  permissionCheck = () => {
    if (this.props.show === true) {
      this.setState({permissionState: true});
      desc = false;
    }
  };

  onCloseAlert = () => {
    if (this.props.show === true) {
      this.setState({permissionState: false});
      desc = true;
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.setState({userSelectState: 'No User'});

    if (this.props?.exception != null) {
      this.setState({descriptionText: this.props?.exception?.description});
      this.setState({checked: this.props?.exception?.reason});
      this.setState({userSelectState: this.props?.exception?.assoc?.username});
      this.props?.orderDetail?.users.map((option, index) => {
        if (option.id == this.props?.exception?.assoc?.id) {
          exceptionIndex = index;
        }
      });
    }
  }
  componentDidUpdate() {
    if (orderExceptionPop == true && this.props?.orderException != undefined) {
      orderExceptionPop = false;
      this.setState({alertShow: true});
    }

    if (clearExceptionPop == true) {
      clearExceptionPop = false;
      if (this.props?.orderClearException?.status) {
        this.setState({clearRecordAlert: true});
      } else {
        this.setState({clearRecordAlertError: true});
      }
    }
  }
  updateDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  changeState = state => {
    if (state === 'activitiesStatus') {
      this.setState({activitiesStatus: true, exceptionStatus: false});
    }
    if (state === 'exceptionStatus') {
      this.setState({exceptionStatus: true, activitiesStatus: false});
    }
  };

  decriptionOnChange = text => {
    this.setState({descriptionText: text});
  };

  activityTextFile = () => {
    let id = this.props?.basicinfo?.id;
    this.props.textFileDownload(id);
  };

  recordExceptionFun = () => {
    const {checked, optionState, descriptionText} = this.state;
    const {basicinfo, recordExceptionMain, orderDetailFun} = this.props;
    if (checked !== '') {
      let id = basicinfo?.id;
      let data = {
        assoc: optionState,
        description: descriptionText,
        reason: checked
      };
      recordExceptionMain(id, data);
      setTimeout(() => {
        orderDetailFun(id);
        orderExceptionPop = true;
      }, 200);
    } else {
      this.setState({errorExceptionAlert: true});
    }
  };

  clearExceptioFun = () => {
    const {basicinfo, clearExceptionMain, orderDetailFun} = this.props;
    const id = basicinfo?.id;
    clearExceptionMain(id);
    setTimeout(() => {
      orderDetailFun(id);
      this.setState({
        descriptionText: '',
        checked: '',
        userSelectState: 'No User',
        initialSelected: !this.state.initialSelected
      });
      clearExceptionPop = true;
    }, 200);
  };
  dropDownToggleActive = () => {
    this.setState({dropDownToggle: !this.state.dropDownToggle});
  };
  userChange = (username, index, option) => {
    exceptionIndex = index;
    this.setState(
      prevState => ({
        userSelectState: username,
        optionState: option
      }),
      () => {
        this.dropDownToggleActive();
      }
    );
  };
  optionClicked = e => {
    this.props.show === true
      ? this.permissionCheck()
      : this.setState({checked: e});
  };
  renderAlerts() {

    const alerts = [
      {state: this.state.alertShow, message: 'Exception Successfully Recorded'},
      {
        state: this.state.errorExceptionAlert,
        message: 'Cannot record exception without reason'
      },
      {state: this.state.clearRecordAlert, message: 'Record exception cleared'},
      {
        state: this.state.clearRecordAlertError,
        message: 'Order does not have exception to clear'
      }
    ];

    return alerts.map(({state, message}, index) => {
      if (state) {
        return (
          <View
            key={index}
            style={
              this.state.windowWidth >= 900
                ? CommonStyles.activitiesAlertStyle
                : CommonStyles.activitiesAlertMobileStyle
            }>
            <LinearGradient
              locations={[0, 1]}
              colors={this.getAlertColors(message)}
              style={[
                CommonStyles.activitiesMainTextStyle,
                this.getAlertShadow(state)
              ]}>
              <Text style={CommonStyles.activitiesTextStyle}>{message}</Text>
              <TouchableOpacity
                style={CommonStyles.activitiesButtonStyle}
                onPress={() => this.handleAlertDismiss(message)}>
                <i
                  className="icon-cancel"
                  style={{fontSize: '22px', color: 'white'}}></i>
              </TouchableOpacity>
            </LinearGradient>
          </View>
        );
      } else {
        return null;
      }
    });
  }

  getAlertColors(message) {
    switch (message) {
      case 'Exception Successfully Recorded':
      case 'Record exception cleared':
        return ['#5bbc31', '#479523', '#3f851d'];
      case 'Cannot record exception without reason':
      case 'Order does not have exception to clear':
        return ['rgb(207,68,55)', 'rgb(122,40,40)'];
      default:
        return [];
    }
  }

  getAlertShadow(state) {
    return state ? {boxShadow: 'rgba(0, 0, 0, 0.9) 0px 0px 3.8px'} : null;
  }

  handleAlertDismiss(message) {
    switch (message) {
      case 'Exception Successfully Recorded':
        this.setState({alertShow: false});
        break;
      case 'Cannot record exception without reason':
        this.setState({errorExceptionAlert: false});
        break;
      case 'Record exception cleared':
        this.setState({clearRecordAlert: false});
        break;
      case 'Order does not have exception to clear':
        this.setState({clearRecordAlertError: false});
        break;
      default:
        break;
    }
  }

  render() {
    const {checked} = this.state;
    let basicinfo = this.props && this.props.basicinfo;
    let userdetail = this.props?.orderDetail?.users;
    let activities = this.props.activities;
    // console.log("cehck activites",activities);
    return (
      <>
        <AlertBoxPermission
          permissionState={this.state.permissionState}
          onCloseAlert={() => this.onCloseAlert()}
        />
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            marginBottom: '5%'
          }}
          showsVerticalScrollIndicator={false}>
          <View style={CommonStyles.packingCamContainer} />
          <View style={CommonStyles.activitiesExpFlex}>
            <OrderInfoHeader
              status={basicinfo.status}
              onSave={this.props.updateBasicInfo}
              updateOrderStatus={this.props.updateOrderStatus}
              show={this.props.show}
              onCancel={this.props.back}
              id={basicinfo}
            />
            {this.renderAlerts()}
            {this.state.windowWidth >= 900 ? (
              <>
                <View style={CommonStyles.orderInfoStyle}>
                  <View style={[globalStyles.m_10, {flexDirection: 'row'}]}>
                    <View style={{width: '50%'}}>
                      <Text style={CommonStyles.activitiesTextStyleThree}>
                        Activity Log
                      </Text>
                    </View>
                    <View style={{width: '50%'}}>
                      <Text
                        style={[
                          globalStyles.py_10,
                          CommonStyles.activitiesTextStyleThree
                        ]}>
                        Packing Exceptions
                      </Text>
                    </View>
                  </View>
                  <View style={{flexDirection: 'row', marginTop: 30}}>
                    <View style={CommonStyles.textFileButton}>
                      <TouchableOpacity
                        testID="textFileButton"
                        onPress={() => {
                          {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.activityTextFile();
                          }
                        }}>
                        <Text style={CommonStyles.activitiesTextStyleSecond}>
                          Save as Text
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        justifyContent: 'center',
                        width: '50%',
                        alignItems: 'center'
                      }}>
                      <Text style={CommonStyles.activitiesTextStyleSecond}>
                        Exception Type
                      </Text>
                    </View>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <View style={CommonStyles.activityView}>
                      <ScrollView
                        style={{height: 480}}
                        showsVerticalScrollIndicator={false}>
                        <View>
                          {activities.map((log, index) => {
                            return (
                              <>
                                <View style={CommonStyles.activityContainer}>
                                  <View style={{flex: 19}}>
                                    <Text
                                      style={{color: 'white', fontSize: 15}}>
                                      {moment(
                                        log.activitytime.split('.')[0]
                                      ).format('dddd MM/DD/YYYY hh:mm:ss A')}
                                    </Text>

                                    <Text
                                      style={{
                                        color: 'white',
                                        marginBottom: 15,
                                        fontSize: 15
                                      }}>
                                      {log.action} -by: {log.username}{' '}
                                      {log.platform}
                                    </Text>
                                  </View>
                                </View>
                              </>
                            );
                          })}
                        </View>
                      </ScrollView>
                    </View>

                    <View style={CommonStyles.activeButtons}>
                      <ActivitiesButtons
                        show={this.props.show}
                        showModal={() => {
                          this.setState({permissionState: true});
                        }}
                        setChecked={value => {
                          this.setState({checked: value});
                        }}
                        style={{
                          marginBottom: '10%',
                          marginLeft: 0,
                          marginLeft: '42%'
                        }}
                        initialSelected={this.state.initialSelected}
                      />
                      <View
                        style={{
                          justifyContent: 'center',
                          textAlign: 'center',
                          marginTop: -49
                        }}>
                        <Text style={{marginLeft: -19, color: 'white'}}>
                          Description
                        </Text>

                        <TextInput
                          multiline={true}
                          editable={desc}
                          numberOfLines={4}
                          style={styles.inputBoxDesk}
                          onChangeText={text => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.decriptionOnChange(text);
                          }}
                          value={this.state.descriptionText}
                        />
                        {/* </View> */}
                      </View>

                      <View
                        style={{
                          width: '200px',
                          marginTop: 10,
                          marginLeft: '43%'
                        }}>
                        <Text style={CommonStyles.associatedTextStyle}>
                          Associated exception with
                        </Text>
                        <View>
                          <TouchableOpacity
                            testID="showUsersList"
                            onPress={() => this.dropDownToggleActive()}>
                            <View style={[styles.mainContainer]}>
                              <View
                                style={[
                                  styles.dropDownToggleStyle,
                                  {flexDirection: 'row'}
                                ]}>
                                <Text style={{color: '#fff'}}>
                                  {this.state.userSelectState}
                                </Text>
                                <i
                                  class="icon-down-open"
                                  style={{
                                    color: 'rgb(205,205,205)',
                                    position: 'absolute',
                                    right: 0
                                  }}
                                />
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {this.state.dropDownToggle && (
                          <ScrollView
                            style={{height: 112}}
                            showsVerticalScrollIndicator={false}>
                            <View
                              testID="usersList"
                              style={CommonStyles.userListStyle}>
                              {userdetail.map((option, index) => {
                                return (
                                  <View key={index}>
                                    <TouchableOpacity
                                      testID="changeUser"
                                      onPress={() => {
                                        this.props.show === true
                                          ? this.permissionCheck()
                                          : this.userChange(
                                              option.username,
                                              index,
                                              option
                                            );
                                      }}
                                      style={{
                                        marginTop: '5%',
                                        backgroundColor:
                                          index == exceptionIndex
                                            ? '#4b5f76'
                                            : ''
                                      }}>
                                      <Text
                                        style={
                                          CommonStyles.activitiesTextStyleFour
                                        }>
                                        {option.username}
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                );
                              })}
                            </View>
                          </ScrollView>
                        )}
                        <View style={{flexDirection: 'row', marginLeft: -30}}>
                          <TouchableOpacity
                            testID="recordExceptionFun"
                            onPress={() => {
                              {
                                this.props.show === true
                                  ? this.permissionCheck()
                                  : this.recordExceptionFun();
                              }
                            }}
                            style={{
                              width:
                                this.state.windowWidth <= 1350 ? '70%' : '90%'
                            }}>
                            <LinearGradient
                              colors={['#95abbf', '#516b83']}
                              style={CommonStyles.quickScanContainer}>
                              <Text style={{color: 'white', fontSize: 15}}>
                                Record Exception
                              </Text>
                            </LinearGradient>
                          </TouchableOpacity>
                          <TouchableOpacity
                            testID="clearExceptionFun"
                            onPress={() => {
                              {
                                this.props.show === true
                                  ? this.permissionCheck()
                                  : this.clearExceptioFun();
                              }
                            }}
                            style={{
                              width:
                                this.state.windowWidth <= 1350 ? '70%' : '90%'
                            }}>
                            <LinearGradient
                              colors={['#95abbf', '#516b83']}
                              style={CommonStyles.quickScanContainer}>
                              <Text style={{color: 'white', fontSize: 15}}>
                                Clear Exception
                              </Text>
                            </LinearGradient>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            ) : (
              <View style={CommonStyles.orderInfoStyle}>
                <View style={{flexDirection: 'row', height: 60}}>
                  <View style={{width: '50%'}}>
                    <TouchableOpacity
                      style={{justifyContent: 'center', textAlign: 'center'}}
                      onPress={this.changeState.bind(this, 'activitiesStatus')}>
                      <Text
                        style={[
                          CommonStyles.activitiesTextStyleThree,
                          {
                            backgroundColor: this.state.activitiesStatus
                              ? 'rgba(15, 39, 58, 0.4)'
                              : '',
                            borderBottomRightRadius: 13,
                            borderBottomLeftRadius: 13,
                            color: this.state.activitiesStatus
                              ? 'white'
                              : 'white',
                            height: this.state.windowWidth <= 435 ? 60 : ''
                          }
                        ]}>
                        Activity Log
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{width: '50%'}}>
                    <TouchableOpacity
                      style={{justifyContent: 'center', textAlign: 'center'}}
                      onPress={this.changeState.bind(this, 'exceptionStatus')}>
                      <Text
                        style={[
                          CommonStyles.activitiesTextStyleThree,
                          {
                            backgroundColor: this.state.exceptionStatus
                              ? 'rgba(15, 39, 58, 0.4)'
                              : '',
                            borderBottomRightRadius: 13,
                            borderBottomLeftRadius: 13,
                            color: 'white'
                          }
                        ]}>
                        Packing Exceptions
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View>
                  {this.state.activitiesStatus && (
                    <Activities
                      {...this.props}
                      permissionCheck={() => this.permissionCheck()}
                      basicinfo={this.props.basicinfo}
                      activities={this.props.activities}
                      exception={this.props.exception}
                      navigation={this.props.navigation}
                      updateBasicInfo={() => this.props.updateBasicInfo()}
                      back={() => this.props.navigation.goBack()}
                      activityTextDownload={() => this.activityTextFile()}
                    />
                  )}
                  {this.state.exceptionStatus && (
                    <Exception
                      {...this.props}
                      showModal={() => {
                        this.setState({permissionState: true});
                      }}
                      basicinfo={this.props.basicinfo}
                      updateBasicInfo={() => this.props.updateBasicInfo()}
                      back={() => this.props.navigation.goBack()}
                      activities={this.props.activities}
                      recordExceptionFun={() => this.recordExceptionFun()}
                      clearExceptioFun={() => this.clearExceptioFun()}
                      exception={this.props.exception}
                      navigation={this.props.navigation}
                      orderDetail={this.props.orderDetail}
                      optionClicked={e => this.optionClicked(e)}
                      descriptionText={this.state.descriptionText}
                      checked={this.state.checked}
                      userSelectState={this.state.userSelectState}
                      userChange={(option, index, username) => {
                        this.userChange(option, index, username);
                      }}
                      decriptionOnChange={text => {
                        this.decriptionOnChange(text);
                      }}
                      optionState={this.state.optionState}
                      dropDownToggleActive={() => this.dropDownToggleActive()}
                      dropDownToggle={this.state.dropDownToggle}
                      permissionCheck={() => this.permissionCheck()}
                      initialSelected={this.state.initialSelected}
                    />
                  )}
                </View>
              </View>
            )}
          </View>
        </ScrollView>
      </>
    );
  }
}
