import React from 'react';
import {View, TextInput, Text, TouchableOpacity} from 'react-native';
import CustomScrollBar from '../../../commons/customScrollBar';
import {OrderTags} from './orderTags';
import {UpdateOrderTag} from './updateOrderTag';
import styles from './styles';
import {getFilteredPriorityTags} from './helperFunctions';

export const OrderTagsView = ({
  searchQuery,
  setSearchQuery,
  isEditing,
  setIsEditing,
  groovePackerTags,
  otherTags,
  selectedTag,
  handleTagUpdate,
  addNewTag,
  newItem,
  isFirstLoad
}) => {
  const {filteredGroovePackerTags, filteredOtherTags, selectedTagList} =
    getFilteredPriorityTags(
      groovePackerTags,
      otherTags,
      selectedTag,
      isFirstLoad
    );
  const tagGroups = [
    {tags: selectedTagList},
    {tags: filteredOtherTags, showDivider: true},
    {tags: filteredGroovePackerTags}
  ];

  return (
    <View style={styles.tagSection} testID='tagContainer'>

      <View style={styles.tagHeader}>
        {!isEditing ? (
          <>
            <Text style={styles.tagHeaderText}>Edit Tag:</Text>
            <TouchableOpacity
              testID='editTag'
              onPress={() => setIsEditing(!isEditing)}>
              <i className="icon-edit" style={styles.icon}></i>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <TouchableOpacity
              testID="addTagContainer"
              style={styles.addNewTagContainer}
              onPress={addNewTag}>
              <TouchableOpacity
                testID="backButton"
                onPress={() => setIsEditing(!isEditing)}>
                <i className="icon-left-open" style={styles.icon}></i>
              </TouchableOpacity>
              <Text
                style={[
                  styles.tagHeaderText,
                  {marginHorizontal: 'auto', width: 100}
                ]}>
                <i className="icon-plus" style={styles.addIcon}></i>
                Add Tag
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>

      <View style={styles.searchContainer}>
        <TextInput
          testID="searchTags"
          style={styles.searchInput}
          placeholder="Search tags..."
          value={searchQuery}
          onChangeText={setSearchQuery}
        />
        <i className="icon-search" style={styles.icon}></i>
      </View>

      <CustomScrollBar>
        {!isEditing ? (
          <View style={styles.tagListContainer}>
            {tagGroups.map((group, index) => (
              <React.Fragment key={index}>
                <OrderTags
                  orderTags={group.tags}
                  selectedTag={selectedTag}
                  setSelectedTag={handleTagUpdate}
                />
                {group.showDivider && <View style={styles.divider} />}
              </React.Fragment>
            ))}
          </View>
        ) : (
          <View style={styles.tagListContainer}>
            <UpdateOrderTag
              groovePackerTags={groovePackerTags}
              otherTags={otherTags}
              newEditingItem={newItem}
            />
          </View>
        )}
      </CustomScrollBar>

    </View>
  );
};
