import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {View, ActivityIndicator, Text, StyleSheet} from 'react-native';
import {AddPriorityTagModal} from './addPriorityTagModal';
import {DraggableList} from './dargAndDropList';
import {ToggleAddTag} from '../../../actions/tagPriorityActions';
import {prepareTagPriorities} from './helperFunctions';

const OrderTagPriorityContainer = ({selectedMenuId}) => {
  const {tagPriorities, displayAddTag, loading, error} = useSelector(
    state => state.tagPriority
  );
  const dispatch = useDispatch();
  const [editingItem, setEditingItem] = useState(null);
  const sortedTagPriorities = useMemo(() => {
    return tagPriorities.sort((a, b) => a.position - b.position);
  }, [tagPriorities]);

  let {userTagsPriorities, priorityTags} =
    prepareTagPriorities(sortedTagPriorities);

  if (loading) {
    return (
      <View style={styles.loaderContainer} testID="loading">
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.errorContainer} testID="error">
        <Text style={styles.errorText}>Failed to load data: {error}</Text>
      </View>
    );
  }
  const handleUpdate = item => {
    setEditingItem(item);
    dispatch(ToggleAddTag(!displayAddTag));
  };
  return (
    <View style={styles.container}>
      <View style={styles.contentWrapper}>
        {selectedMenuId === 2 && priorityTags.length > 0 && (
          <DraggableList
            tagPriorities={priorityTags}
            onEdit={handleUpdate}
            selectedMenuId={selectedMenuId}
          />
        )}
        {selectedMenuId === 3 && userTagsPriorities.length > 0 && (
          <DraggableList
            tagPriorities={userTagsPriorities}
            onEdit={handleUpdate}
            selectedMenuId={selectedMenuId}
            lastIndex={priorityTags.length + 1}
          />
        )}
      </View>
      {displayAddTag && (
        <AddPriorityTagModal
          isVisible={displayAddTag}
          isUserTag={selectedMenuId === 3 ? true : false}
          onClose={() => {
            dispatch(ToggleAddTag(!displayAddTag));
            setEditingItem(null);
          }}
          editingItem={editingItem}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#262B31',
    width: '97%',
    marginLeft: 3,
    marginRight: 5,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    marginTop: 2
  },
  contentWrapper: {
    backgroundColor: '#323943',
    padding: 5,
    width: '97%',
    marginHorizontal: '1%',
    marginTop: '4%',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15
  },
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorText: {
    color: 'red',
    fontSize: 16
  }
});

export default OrderTagPriorityContainer;
