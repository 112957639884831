import {
  GET_ORDER_TAG_PRIORITY,
  UPDATE_ORDER_TAG_PRIORITY,
  CREATE_ORDER_TAG_PRIORITY,
  GET_ORDER_TAG_PRIORITY_LOADING,
  UPDATE_ORDER_TAG_PRIORITY_POSITION,
  GET_ORDER_TAG_PRIORITY_ERROR,
  DELETE_ORDER_TAG_PRIORITY,
  ADD_TAG
} from '../constants';

const initialState = {
  tagPriorities: [],
  loading: false,
  displayAddTag: false,
  error: null
};

export default function orderTagPriorityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORDER_TAG_PRIORITY_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ORDER_TAG_PRIORITY:
      return {
        ...state,
        tagPriorities: action.payload,
        loading: false,
        error: null
      };
    case GET_ORDER_TAG_PRIORITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_ORDER_TAG_PRIORITY:
      return {
        ...state,
        tagPriorities: [...state.tagPriorities, action.payload],
        loading: false,
        error: null
      };
    case UPDATE_ORDER_TAG_PRIORITY:
      return {
        ...state,
        tagPriorities: state.tagPriorities.map(priority =>
          priority.id === action.payload.id ? action.payload : priority
        ),
        loading: false,
        error: null
      };
    case UPDATE_ORDER_TAG_PRIORITY_POSITION:
      return {
        ...state,
        tagPriorities: state.tagPriorities.map(priority => {
          const updatedPriority = action.payload.find(
            p => p.id === priority.id
          );
          return updatedPriority ? updatedPriority : priority;
        }),
        loading: false,
        error: null
      };
    case DELETE_ORDER_TAG_PRIORITY:
      return {
        ...state,
        tagPriorities: state.tagPriorities.filter(
          priority => priority.id !== action.payload
        ),
        loading: false,
        error: null
      };
    case ADD_TAG:
      return {
        ...state,
        displayAddTag: action.payload
      };
    default:
      return state;
  }
}
