import React from 'react';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';

export const FilterMenu = ({onPress, selected = 1}) => {

  const menu = [
    {
      id: 1,
      label: 'Filters',
      icon: 'icon-filter'
    },
    {
      id: 2,
      label: 'Priorities',
      icon: 'icon-list'
    },
    {
      id: 3,
      label: 'Users',
      icon: 'icon-user-circle-o'
    }
  ];

  return (
    <View style={styles.menuContainer}>
      {menu.map(item => (
        <TouchableOpacity
          key={item.id}
          style={[
            styles.menuItem,
            selected === item.id && [styles.activeMenuItem]
          ]}
          testID='selectMenuButton'
          onPress={() => onPress(item.id)}
        >
          <i className={item.icon} style={styles.icon}></i>
          <Text
            style={[
              styles.label,
              selected === item.id && styles.activeLabel
            ]}>
            {item.label}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottomColor: '#262B31',
    borderBottomWidth: 2,
    paddingBottom: 8
  },
  menuItem: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 5,
    borderRadius: 8
  },
  activeMenuItem: {
    borderColor: '#475058',
    borderWidth: 1,
    borderRadius: 10,
    padding: 0
  },
  activeIcon: {
    color: '#ffffff'
  },
  icon: {
    fontSize: 16,
    color: '#FFFFFF',
    marginBottom: 4
  },
  label: {
    fontSize: 12,
    color: '#E0E7EF',
    marginTop: -6,
    fontFamily: 'Poppins_300Light'
  },
  activeLabel: {
    color: '#ffffff'
  }
});

export default FilterMenu;
